import React, { useState, useEffect } from "react";
import { Route, useNavigate, useLocation, Routes } from "react-router-dom";
import { styled, createTheme, ThemeProvider, createStyles } from '@mui/material/styles';
import { connect, useDispatch, useSelector } from "react-redux";
import * as actionType from "../../Redux/constants/actionTypes";
import decode from "jwt-decode";
import { createStyled } from "@mui/system";
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { Card, CardMedia, } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { mainListItems, } from './listItems';

import useStyles from "./styles";

import Logo from '../../Img/Logo.png'

import TextToSpeechComponent from './1_TextToSpeech/TextToSpeechComponent';
import ClientMenuInputs from './2_MenuInputs/ClientMenuInputs';
import ClientMenuLinks from './3_MenuLinks/ClientMenuLinks';
import QrCodeComponent from './3_MenuLinks/QrCodeComponent';


import DragDropFileUpload from './4_Preview/DragDropFileUpload';
import AvatarCreatorApp from './4_Preview/AvatarCreator';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://stripar.net.com/">
        https://stripar.net.com/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },

});


function htmlComponent() {
  return <html><head>
    <script src='https://aframe.io/releases/1.4.0/aframe.min.js'></script>
    <script src='//cdn.8thwall.com/web/aframe/aframe-extras-6.1.1.min.js'></script></head>  <body>
      <a-scene><a-assets>
        <a-asset-item id='canModel' src='./models/Augie_NewBot_Male.glb' preload='auto'></a-asset-item></a-assets>

        <a-entity id='avatar' gltf-model='#canModel' position='0 0 -2' scale='0.1 0.1 0.1' animation-mixer='clip: BoyBot_clip; loop: repeat'>
        </a-entity>

        <a-sky color='#ECECEC'></a-sky>
      </a-scene>
    </body></html>
}


const text1 = `

<head>
<meta charset='utf-8'>
<title>stripar</title>
<meta name='description' content='Model Viewer (VR / AR) • A-Frame'>
<meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
<script src='https://aframe.io/releases/1.2.0/aframe.min.js'></script>


</head>

<body>
<a-scene id='aScene'>
  <a-assets>
    <img id="ground" src="textures/concept_v005_page03.jpeg">
    <img id="world" src="textures/puydesancy.jpg">
  </a-assets>
<a-entity id='myPlane'  geometry="primitive: plane"  position='0 1 -2' scale='2 1 1'  material="transparent: true; src: #ground"
animation="property: rotation; from: 0 -10 0; to: 0 10 0; loop: true; dur: 5000; dir: alternate;"
animation__position="property: position; to: 0 1.4 -2; loop: true; dur: 90000; dir: alternate;"> </a-entity>
<a-sky src="#world" rotation="0 -130 0"></a-sky>

</a-scene>
</body>

</html>

`


export default function ClientMenu() {
  let interval;
  const ref = React.useRef();
  const [open, setOpen] = React.useState(true);
  const [rpm, setRpm] = React.useState(true);
  const classes = useStyles;
  console.log(useStyles)
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const logout = () => {
    clearInterval(interval);
    dispatch({ type: actionType.LOGOUT });

    history("/");

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);
      console.log(decodedToken);

      interval = setInterval(() => {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          logout(); clearInterval(interval);

        };
        //console.log(interval)
      }, 1000);


    }

    setUser(JSON.parse(localStorage.getItem("profile")));
    
  }, [location]);

  function handleClick(e) {
    console.log(e);
    history("/auth");
  }

  const handleFileUpload = (file) => {
    console.log(file);
    //var mesh = ref.current.contentWindow.document.getElementById('avatar').getObject3D('mesh');

    var plane = ref.current.contentWindow.document.getElementById('myPlane');

    const texture1 = new window.THREE.TextureLoader().load(file, function name(texture) {
      plane.object3D.traverse(function (node) {
        if (node.isMesh) {
          console.log(node)
          node.material.map = texture;
          node.material.needsUpdate = true;
        }
      })
    })
    //if (mesh.getObjectByName('faceShape')) mesh.getObjectByName('faceShape').material.map = texture1
    //if (mesh.getObjectByName('Wolf3D_Outfit_Top')) mesh.getObjectByName('Wolf3D_Outfit_Top').material.map = texture1

  };

  const changeAvatar = (cb) => {

    (cb == 'RPM') ? setRpm(true) : setRpm(false)
  }
  React.useEffect(() => {
    //ref.current.contentWindow.document.querySelector('a-scene').addEventListener('loaded', function () { console.log('Ready') })

    const LoopMode = {
      once: window.THREE.LoopOnce,
      repeat: window.THREE.LoopRepeat,
      pingpong: window.THREE.LoopPingPong,
    }
    function wildcardToRegExp(s) {
      return new RegExp(`^${s.split(/\*+/).map(regExpEscape).join('.*')}$`)
    }
    function regExpEscape(s) {
      return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    }


    /*
        ref.current.onload = function () {
          console.log(ref.current.contentWindow.document.querySelector('a-scene'))
          //console.log(ref.current.contentWindow.document.getElementById('canModel').el.getObject3D('mesh')) 
    
          ref.current.contentWindow.document.getElementById('avatar').addEventListener('model-loaded', (e) => {
            var mesh = ref.current.contentWindow.document.getElementById('avatar').getObject3D('mesh').getObjectByName('faceShape');
            const customImg1 = ref.current.contentWindow.document.getElementById("imgTexture1").src;
            const texture1 = new window.THREE.TextureLoader().load(customImg1);
            setTimeout(() => {
              //mesh.material.map = texture1
              mesh.material.map.flipY = true;
              //mesh.material.roughness = 0.0
              //mesh.material.metalness = 0.0
              mesh.material.needsUpdate = false
    
              console.log(mesh.material)
            }, 1000);
    
          })
    
    
        }
    
        */
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', backgroundColor: 'rgb(28 30 33);', }} >
        <CssBaseline />
        <AppBar position="absolute" color="inherit" open={!open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Card sx={{
              display: 'flex',
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              m: 1
            }}>
              <CardMedia
                component="img"
                sx={{ display: 'flex', flexDirection: 'column', width: 30, }}
                image={Logo}
                alt="Live from space album cover"
              />
            </Card>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              stripar Client Portal
            </Typography>
            <Typography
              component="h2"
              variant="h6"
              color="#8b8b8b"
              noWrap
            >
              {`Plan: ${user.subcription?.planName || 'Сhoose plan'}`}
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={!open}>
          <Toolbar

            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],

            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/*secondaryListItems*/}
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
      

          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 500,
                }}
              >
                <iframe ref={ref} style={{
                  position: "flex",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  width: "100%",
                  height: "100%"
                }} srcDoc={process.env.NODE_ENV === "development" ? text1 : text1
                }
                  src="about:blank"></iframe>
              </Paper>

              {/*rpm ? <AvatarCreatorApp refToAscene={ref} /> : null */}
            </Grid>


          </Container>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={6}>
                <TextToSpeechComponent key={Math.random() + ""} refToAscene={ref} changeAvatar={changeAvatar} />
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <QrCodeComponent />
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <DragDropFileUpload onFileUpload={handleFileUpload} />
              </Grid>
            </Grid>
          </Container>
          <ClientMenuInputs/>
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
