import React, { Component, useRef } from "react";
import ReactDOM from "react-dom";
import QRCode from "qrcode.react";
import { Box, Stack, Typography, Button, Container, Link } from '@mui/material';

import { connect } from "react-redux";

class QrCodeComponent extends Component {
    constructor(props) {
        super(props);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeSalesPersonName = this.onChangeSalesPersonName.bind(this);
        this.onChangeClientCompanyName = this.onChangeClientCompanyName.bind(this);
        this.onChangeClienContactName = this.onChangeClienContactName.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeSalesDeckUrl = this.onChangeSalesDeckUrl.bind(this);
        this.onChangeIdVoice = this.onChangeIdVoice.bind(this);
        this.saveTutorial = this.saveTutorial.bind(this);
        this.newTutorial = this.newTutorial.bind(this);
        this.downloadQRCode = this.downloadQRCode.bind(this);

        this.state = {
            id: null,
            title: "",
            description: "",
            published: false,
            submitted: false,
            salespersonname: "",
            clientcompanyname: "",
            cliencontactname: "",
            country: "",
            salesdeckurl: "",
            idvoice: this.props.menuUrlProp.url || "test",
            idExp: ""
        };
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value,
        });
    }

    onChangeDescription(e) {
        this.setState({
            description: e,
        });
    }

    onChangeSalesPersonName(e) {
        this.setState({
            ...this.state,
            salespersonname: e.target.value,
            idvoice: this.props.menuUrlProp.url,
        });

        console.log(this.props.menuUrlProp.url);
        console.log(this.state.idvoice);
    }
    onChangeClientCompanyName(e) {
        this.setState({
            clientcompanyname: e.target.value,
        });
    }
    onChangeClienContactName(e) {
        this.setState({
            cliencontactname: e.target.value,
        });
    }
    onChangeCountry(e) {
        this.setState({
            country: e.target.value,
        });
    }
    onChangeSalesDeckUrl(e) {
        this.setState({
            salesdeckurl: e.target.value,
        });
    }

    onChangeIdVoice(e) {
        this.setState({ ...this.state, idvoice: this.props.menuUrlProp });
    }

    saveTutorial() {
        this.onChangeIdVoice();
        var data = {
            //title: this.state.title,
            description: this.state.description,
            clientcompanyname: this.state.clientcompanyname,
            salespersonname: this.state.salespersonname,
            cliencontactname: this.state.cliencontactname,
            country: this.state.country,
            salesdeckurl: this.state.salesdeckurl,
            idvoice: this.state.idvoice,
        };

    }

    newTutorial() {
        this.setState({
            id: null,
            title: "",
            description: "",
            published: false,
            salespersonname: "",
            clientcompanyname: "",
            cliencontactname: "",
            country: "",
            salesdeckurl: "",
            idvoice: this.props.menuUrlProp,
            submitted: false,
        });
    }

    downloadQRCode() {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${this.state.salesdeckurl}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.experience !== this.props.experience) {
            // Do whatever you want
            //console.log(this.props.experience.experienceData.result.idExperience)
            this.setState({
                idExp: this.props.experience.experienceData.result.idExperience,
            });

        }


        //console.log(this.props.customEditData)
        //console.log(this.props.experience?.experienceData?.result?.idExperience)

    }
    render() {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Container sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    <QRCode
                        id="qr-gen"
                        value={`https://${process.env.NODE_ENV === "development" ? "localhost:3001" : "exp.stripar.net"}/?id=` + this.state.idExp}

                        level={"H"}
                        includeMargin={true}
                    />

                </Container>
                <Typography variant="body2" gutterBottom>

                    <Link href={`https://${process.env.NODE_ENV === "development" ? "localhost:3001" : "exp.stripar.net"}/?id=` + this.state.idExp} target="_blank">{`https://${process.env.NODE_ENV === "development" ? "localhost:3001" : "exp.stripar.net"}/?id=` + this.state.idExp}</Link>

                </Typography>
                <Button variant="contained" onClick={this.downloadQRCode} fullWidth>Download</Button>
            </Box >
        );
    }
}

const mapStateToProps = (state) => {
    //console.log(state.settingsLeft)
    return {
        ARModeProp: state.ARMode,
        menuUrlProp: state.voiceUrl,
        experience: state.experienceState,
        customEditData: state.customEditState.customEditData
    };
};



export default connect(mapStateToProps, null)(QrCodeComponent);
